import { cn } from '@cardo/lib';
import { Badge, Button } from '@cardo/ui';
import { FeaturedTripReportComponent, OmitComponentAttrs } from '@cardo/types';
import { Link } from '@remix-run/react';
import UserCardLine from '../UserCardLine';
import { getTripReportTypeDetails } from '../TripReportCard';

export default function FeaturedTripReport({
  heading,
  reversed,
  tripReport,
}: OmitComponentAttrs<FeaturedTripReportComponent>) {
  const attrs = tripReport?.data?.attributes;

  const { backgroundImage, badges } = getTripReportTypeDetails({
    tripReport: tripReport?.data,
  });

  return (
    <div className="flex flex-grow flex-col w-full">
      {heading && (
        <h2 className={cn('text-start text-2xl  mb-8 sm:mb-14')}>{heading}</h2>
      )}
      <div
        className={cn(
          'flex gap-x-0 md:gap-x-10 gap-y-10 w-full',
          reversed
            ? 'flex-col-reverse md:flex-row-reverse'
            : 'flex-col md:flex-row',
        )}
      >
        <div
          className="w-full md:w-[55%] h-[255px] md:h-[510px] bg-cover bg-center rounded-xl"
          style={{ backgroundImage }}
        />
        <div className="w-full md:w-[45%] flex flex-col gap-5 md:mt-10">
          <div className="flex flex-wrap gap-2">
            {badges.map(
              (badge, i) =>
                badge && (
                  <Badge
                    key={badge}
                    color={i ? 'yellow' : 'blue'}
                    className="px-3 py-1 rounded-2xl text-md font-semibold max-w-full"
                  >
                    <p className="truncate">{badge}</p>
                  </Badge>
                ),
            )}
          </div>
          <h2 className="text-lg md:text-xl">{attrs?.title}</h2>
          {attrs?.influencer?.data && (
            <UserCardLine influencer={attrs.influencer.data} />
          )}
          <p className="text-sm md:text-base line-clamp-8">{attrs?.tagline}</p>
          <Link to={`/trip-reports/${attrs?.slug ?? ''}`}>
            <Button outline large>
              Read More
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
