import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { cn } from '@cardo/lib';
import useEmblaCarousel from 'embla-carousel-react';
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5';

export default function CardCarousel({
  className,
  children,
  slidesToShow = 1.16,
}: {
  className?: string;
  children: ReactNode;
  slidesToShow?: number;
}) {
  const childCount = React.Children.count(children);
  const isMultipleChildren = childCount > 1;

  const [emblaRef, emblaApi] = useEmblaCarousel();

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const onSelect = useCallback(() => {
    if (!emblaApi || !isMultipleChildren) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi, isMultipleChildren]);

  useEffect(() => {
    if (!emblaApi || !isMultipleChildren) return;
    onSelect();
    emblaApi.on('select', onSelect);
    emblaApi.on('reInit', onSelect);
  }, [emblaApi, onSelect, isMultipleChildren]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className={cn('w-full relative pb-6 overflow-hidden', className)}>
      {isMultipleChildren && (
        <div className="absolute top-[40%] flex justify-between w-full z-10">
          <IoArrowBackSharp
            onClick={scrollPrev}
            className={cn(
              `sm:hover:text-6xl transition-all z-30 text-4xl sm:text-5xl bg-slate-500 bg-opacity-70 rounded-full p-2`,
              !canScrollPrev
                ? 'opacity-30 cursor-not-allowed'
                : 'cursor-pointer',
            )}
          />
          <IoArrowForwardSharp
            onClick={scrollNext}
            className={cn(
              `sm:hover:text-6xl transition-all z-30 text-4xl sm:text-5xl bg-slate-500 bg-opacity-70 rounded-full p-2`,
              !canScrollNext
                ? 'opacity-30 cursor-not-allowed'
                : 'cursor-pointer',
            )}
          />
        </div>
      )}

      <div
        ref={emblaRef}
        className={cn('overflow-hidden', isMultipleChildren ? '' : 'w-full')}
      >
        <div
          className={cn(
            'flex h-full pb-4 ',
            isMultipleChildren ? 'gap-4' : 'w-full',
          )}
        >
          {React.Children.map(children, (child) => (
            <div
              className={cn(
                'min-w-0 box-border flex-none',
                !isMultipleChildren && 'w-full',
                isMultipleChildren && slidesToShow === 1 && 'w-full',
                isMultipleChildren &&
                  slidesToShow !== 1 &&
                  `w-1/${slidesToShow}`,
              )}
              style={
                isMultipleChildren
                  ? {
                      flex: `0 0 ${100 / slidesToShow}%`,
                    }
                  : undefined
              }
            >
              <div className="h-full flex">
                <div className="flex-1 flex flex-col">{child}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
