import type { TestimonialComponent, OmitComponentAttrs } from '@cardo/types';
import HTMLContent from './HTMLContent';

export default function Testimonial({
  content,
}: OmitComponentAttrs<TestimonialComponent>) {
  return (
    <div className="flex flex-grow flex-col items-center bg-[#073E5D] p-5 md:p-12">
      {content && (
        <HTMLContent
          content={content}
          className="text-white text-base md:text-2xl font-semibold text-center  leading-[1.5] "
        />
      )}
    </div>
  );
}
