import type {
  ImageWithWysiwygContentComponent,
  OmitComponentAttrs,
} from '@cardo/types';
import HTMLContent from './HTMLContent';
import { cn } from '@cardo/lib';

export default function ImageWithHtmlContent({
  heading,
  description,
  image,
}: OmitComponentAttrs<ImageWithWysiwygContentComponent>) {
  if (!image) return null;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-10 p-0 md:p-12">
      <figure key={image.data.id} className="flex w-full ">
        <img
          src={image.data.attributes.url}
          alt={image.data.attributes.name}
          className="mx-auto h-full max-h-[200px] rounded-md  md:rounded-lg md:max-h-[385px] w-full object-contain shadow-md "
        />
      </figure>
      <div className="text-start px-5 pb-5 md:pb-0 md:px-0">
        {heading && (
          <h2
            className={cn(
              'text-xl md:text-3xl text-black font-bold mb-3 md:mb-8',
            )}
          >
            {heading}
          </h2>
        )}
        {description && (
          <HTMLContent
            content={description}
            className="text-black text-sm md:text-lg font-semibold leading-[1.5] "
          />
        )}
      </div>
    </div>
  );
}
