import type { BlockComponent } from '@cardo/types';
import BlogPosts from './BlogPosts';
import CreditCard from './CreditCard';
import CreditCardCategories from './CreditCardCategories';
import FeaturedCards from './FeaturedCards';
import HTMLContent from './HTMLContent';
import ImageCarousel from './ImageCarousel';
import CalculatorBase from './CalculatorBase';
import HTMLEmbed from './HTMLEmbed';
import CreditCardCtaBase from './CreditCardCtaBase';
import CallToActionSection from './CallToActionSection';
import TripReportCards from './TripReportCards';
import Testimonial from './Testimonial';
import ImageWithHtmlContent from './ImageWithHtmlContent';
import FeaturedTripReport from './FeaturedTripReport';
import Locations from './Locations';
import Airlines from './Airlines';

const getBlockComponent = (block: BlockComponent) => {
  if (!block.id) return null;

  switch (block.__component) {
    case 'client.credit-card': {
      const { id, __component, ...rest } = block;
      return <CreditCard {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.credit-card-categories': {
      const { id, __component, ...rest } = block;
      return <CreditCardCategories {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.blog-posts': {
      const { id, __component, ...rest } = block;
      return <BlogPosts {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.wysiwyg-content': {
      const { id, __component, ...rest } = block;
      return <HTMLContent {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.featured-cards': {
      const { id, __component, ...rest } = block;
      return <FeaturedCards {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.trip-report-cards': {
      const { id, __component, ...rest } = block;
      return <TripReportCards {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.testimonial': {
      const { id, __component, ...rest } = block;
      return <Testimonial {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.image-with-wysiwyg-content': {
      const { id, __component, ...rest } = block;
      return <ImageWithHtmlContent {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.featured-trip-report': {
      const { id, __component, ...rest } = block;
      return <FeaturedTripReport {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.locations': {
      const { id, __component, ...rest } = block;
      return <Locations {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.airlines': {
      const { id, __component, ...rest } = block;
      return <Airlines {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.image-carousel': {
      const { id, __component, ...rest } = block;
      return <ImageCarousel {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.calculator': {
      const { id, __component, ...rest } = block;
      return <CalculatorBase {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.html-embed': {
      const { id, __component, ...rest } = block;
      return <HTMLEmbed {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.credit-card-cta': {
      const { id, __component, ...rest } = block;
      return <CreditCardCtaBase {...rest} key={`${id}-${__component}`} />;
    }
    case 'client.call-to-action-section': {
      const { id, __component, ...rest } = block;
      return <CallToActionSection {...rest} key={`${id}-${__component}`} />;
    }
  }

  return null;
};

export const Blocks = ({ blocks }: { blocks: BlockComponent[] }) => {
  return <>{blocks.map(getBlockComponent)}</>;
};

export const Block = ({ block, ...rest }: { block: BlockComponent }) => {
  if (!block || !block.id || !block.__component) return null;
  return <>{getBlockComponent({ ...block, ...rest })}</>;
};
