import { cn } from '@cardo/lib';
import { LocationsComponent, OmitComponentAttrs } from '@cardo/types';
import { Link } from '@remix-run/react';
import { IoLocationOutline } from 'react-icons/io5';

export default function Locations({
  heading,
  locations,
}: OmitComponentAttrs<LocationsComponent>) {
  return (
    <div className="flex flex-grow flex-col items-center">
      {heading && (
        <h2 className={cn('text-center text-2xl mb-8 md:mb-14')}>{heading}</h2>
      )}
      <div className="flex flex-wrap gap-4 w-full">
        {locations.data &&
          locations.data.map((location, index) => (
            <Link
              key={index}
              to={`/trip-reports/location/${location.attributes.slug}`}
              className="group flex items-center gap-1 md:gap-2 bg-white rounded-full no-underline  p-2 md:p-3  shadow-sm border border-gray-100 hover:shadow-md transition-all duration-200 cursor-pointer"
            >
              <IoLocationOutline className="text-base md:text-xl text-gray-400 group-hover:text-blue-500 transition-colors" />
              <div className="flex  items-center gap-2">
                <span className="text-xs md:text-sm font-medium text-gray-900">
                  {location.attributes.placeName}
                </span>
                <span className="text-xs md:text-sm  text-gray-500">
                  {location.attributes.country}
                </span>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
