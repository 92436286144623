import { cn } from '@cardo/lib';
import { useRef } from 'react';
import { formatInt } from '../utils';

export function CalculatorInput({
  value,
  currency = false,
  className,
  ...props
}: {
  currency?: boolean;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null);

  const formattedValue =
    value != null && formatInt(parseFloat(value as string));

  return (
    <div
      className={cn(
        'relative flex w-full',
        !className?.includes('sm:w-') && 'flex-shrink-0 sm:w-32',
        className,
      )}
    >
      {currency && (
        <div className="absolute left-2.5 flex h-full items-center">$</div>
      )}
      <input
        ref={ref}
        {...props}
        value={formattedValue || ''}
        className={cn(
          'w-full rounded border-gray-300 py-1',
          currency && 'pl-6',
        )}
        onFocus={(event) => {
          ref.current?.select();
          if (typeof props.onFocus === 'function') {
            props.onFocus(event);
          }
        }}
      />
    </div>
  );
}
