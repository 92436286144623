import { cn } from '@cardo/lib';
import type { BlogPostsComponent, OmitComponentAttrs } from '@cardo/types';
import BlogCard from '../BlogCard';
import CardCarousel from '../CardCarousel';

export default function BlogPosts({
  posts,
  heading,
  subheading,
}: OmitComponentAttrs<BlogPostsComponent>) {
  return (
    <div className="flex flex-col items-center">
      {heading && (
        <h2
          className={cn('text-center text-2xl', {
            'mb-6 sm:mb-14': !subheading,
            'mb-3': !!subheading,
          })}
        >
          {heading}
        </h2>
      )}
      {subheading && <h5 className="mb-6 sm:mb-14">{subheading}</h5>}
      {posts.length > 0 && (
        <>
          <div className="hidden md:grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 w-full">
            {posts.map((post) =>
              post.blog?.data?.id ? (
                <BlogCard
                  key={post.blog.data.id}
                  blog={post.blog.data}
                  className="flex-grow"
                />
              ) : null,
            )}
          </div>
          <CardCarousel className="flex md:hidden flex-col pb-1">
            {posts.map((post) =>
              post.blog?.data?.id ? (
                <BlogCard
                  key={post.blog.data.id}
                  blog={post.blog.data}
                  className="flex-grow"
                />
              ) : null,
            )}
          </CardCarousel>
        </>
      )}
    </div>
  );
}
